<!--  -->
<template>
  <div class=''>
    <div style="font-size: 35px">急诊</div>
    <el-divider></el-divider>
    <el-input
        style="width: 30%"
        placeholder="请输入关键词查询"
        v-model="search"
        clearable>
    </el-input>
    <el-table :data="docData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))">
      <el-table-column label="医生姓名" prop="realName"></el-table-column>
      <el-table-column label="称号" prop="title"></el-table-column>
      <el-table-column label="简介" prop="introduction">
        <template v-slot="scope1">
          <el-popover
              title="医生简介"
              placement="right"
              trigger="click"
              width="auto">
            <div style="font-size: 15px">{{scope1.row.introduction}}</div>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>/
      <el-table-column label="科室" prop="department"></el-table-column>

      <el-table-column label="病人" prop="patient">
          <el-select v-model="patient_select" v-bind:valueDate = patient_select placeholder="请选择">
            <el-option
                v-for="item in patient_list"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
      </el-table-column>

      <el-table-column label="症状备注" prop="Remark" width="200%">
        <template v-slot="scope2">
          <el-input
              type="textarea"
              :rows="1"
              placeholder="请简要描述症状"
              v-model="scope2.row.textarea" v-bind:valueText=scope2.row.textarea>
          </el-input>
        </template>
      </el-table-column>

      <el-table-column label="提交预约" prop="Submit">
        <!--        考虑到效率问题，应该限制单个用户的预约总量，这里还没有写，考虑读取用户的预约记录并限制已有多条预约未完成的用户提交新预约-->
        <template v-slot="scope3">
          <el-button type="primary"
                     @click="appointmentSubmit(scope3.row)">提交</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      search:'',
      docData: [],
      patient_list: [],
      patient_select: '',
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>